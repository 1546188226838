import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    nb_NO: {
      translation: {
        welcome_title: 'Velkommen til Carrot',
        company_code: 'Bedriftskode',
        log_in: 'Logg inn',
        popover_title: 'Hva er bedriftskode?',
        popover_text: `
          <h3>Hva er bedriftskoden?</h3>
          <p>Bedriftskoden er innloggingskoden som lar systemet vite hvilken bedrift du representerer.</p>
          <p>Bedriftskoden har ni bokstaver.</p>
          <p>Dersom du har problemer, kontakt <a>support@carrot.tech</a></p>

          <h3>Carrot for Leietakere/Tenants (web og mobilapp)</h3>
          <p>Bedriftskoden har blitt sendt via epost, som regel til butikksjefen/daglig leder. Eposten er sent fra <a>support@carrot.tech</a>. (Husk å sjekke spamfolderen i tilfelle den har endt opp der).</p>
          <p>Driftssjefen på bygget har også mulighet til å finne bedriftskoden i Carrot Insight og dele den med deg.</p>
          <p>Dersom du bruker mobilappen: Når du er innlogget på appen kan du finne bedriftskoden under "Profil", slik at du kan dele den med kollegaer.</p>

          <h3>Carrot Tablet App</h3>
          <p>Man logger inn på en tablet ved å bruke en (bedrifts)kode som representerer hele bygget. Brukeren velger så hvilken bedrift å kaste for i løpet av flyten i appen.</p>
          <p>(Bedrifts)koden som representerer bygget kan finnes i Carrot Insight av driftssjefen på bygget.</p>
        `,
        error_unknown_company_code1: `Ukjent bedriftskode`,
        error_unknown_company_code2: `Vennligst prøv igjen eller kontakt din administrator.`,
        error_coverage1: 'Du mangler visst dekning',
        error_coverage2: 'Sørg for dekning og prøv igjen.',
        error_login1: 'Tillatte forsøk på innlogging på denne URLen er oppbrukt',
        error_login2: 'Prøv å laste siden på nytt før du prøver å logge inn igjen. Dersom du fortsatt ikke får tilgang, gå til <a>http://tenants.carrot.tech</a> og forsøk innlogging der.',
        error_state1: 'Innloggingsforsøk ikke funnet',
        error_state2: 'Last inn innloggingsvinduet på nytt og prøv igjen. Hvis du fortsatt ikke får tilgang, kontakt support@carrot.tech',
        error_system1: 'Systemfeil',
        error_system2: 'Prøv å laste siden på nytt før du prøver å logge inn igjen. Dersom du fortsatt ikke får tilgang, vennligst kontakt support@carrot.tech',
        error_no_price1: 'Pris ikke funnet',
        error_no_price2: 'Vennligst kontakt support@carrot.tech.',
        error_terminal1: 'Bygg ikke funnet',
        error_terminal2: 'Vennligst kontakt support@carrot.tech.',
        payment_title: 'Kom i gang med Carrot',
        payment_info: 'Med et Carrot-abonnement kan du enkelt håndtere ditt avfall og gjenvinning. Kom i gang ved å klikke på knappen under.',
        to_payment: 'Til betaling',
        price: 'Pris',
        subscription_title: 'Abonnement',
        subscription_info: 'Få detaljert gjenvinningsdata og oppfølging fra Carrot',
        yearly: 'per år',
      },
    },
    en_US: {
      translation: {
        welcome_title: 'Welcome to Carrot',
        company_code: 'Company code',
        log_in: 'Login',
        popover_title: 'What is the company code?',
        popover_text: `
          <h3>What is the company code?</h3>
          <p>The company code is the login code that allows the system to know which company you represent.</p>
          <p>The company code consists of nine letters.</p>
          <p>In case of problems, contact <a>support@carrot.tech</a></p>

          <h3>Carrot for Tenants (web and mobile app)</h3>
          <p>The company code has been sent via email, often to the store manager/general manager. The mail is sent by <a>support@carrot.tech</a>. (Remember to check the spam folder, as it might have ended up there).</p>
          <p>The operations manager of the building is also able to retrieve the company code from Carrot Insight and share it with you.</p>
          <p>If using the mobile app: When you are logged into the app, you can find the company code under «Profile», so that is can be shared with colleagues.</p>

          <h3>Carrot Tablet App</h3>
          <p>Logging into a tablet is done using a (company) code that represents the whole building. The user then chooses what company to throw for during the flow of the app.</p>
          <p>The (company) code representing the building can be found in Carrot Insight by the operations manager of the building.</p>
        `,
        error_unknown_company_code1: `Unknown company code`,
        error_unknown_company_code2: `Please try again or contact your administrator.`,
        error_coverage1: 'Seems you have no coverage!',
        error_coverage2: 'Make sure you have coverage and try again.',
        error_login1: 'Permitted attempts at login on this URL are exhausted',
        error_login2: 'Please try refreshing the page before trying to log in again. If you still cannot get access, please go to <a>http://tenants.carrot.tech</a> and attempt to log in there.',
        error_state1: 'Login attempt not found',
        error_state2: "Reload the login window and try again. If you still can't access, contact support@carrot.tech",
        error_system1: 'System error',
        error_system2: 'Please try refreshing the page before trying to log in again. If you still cannot get access, please contact support@carrot.tech',
        error_no_price1: 'Price not found',
        error_no_price2: 'Please contact support@carrot.tech.',
        error_terminal1: 'Building not found',
        error_terminal2: 'Please contact support@carrot.tech.',
        payment_title: 'Get started with Carrot',
        payment_info: 'With a Carrot subscription, you can easily manage your waste and recycling. Get started by clicking the button below.',
        to_payment: 'To payment',
        price: 'Price',
        subscription_title: 'Subscription',
        subscription_info: 'Get detailed recycling data and follow up from Carrot',
        yearly: 'per year',
      },
    },
  },
  fallbackLng: 'nb_NO',
  interpolation: {
    escapeValue: false,
  },
});
